import type { TranslationKey } from '@change-corgi/core/i18n';
import { translationKey } from '@change-corgi/core/i18n';
import { Translate } from '@change-corgi/core/react/i18n';
import { Icon } from '@change-corgi/design-system/components/icon';
import type { IconComponent } from '@change-corgi/design-system/icons';
import { iconEmail, iconFacebook, iconTwitter, iconWhatsapp } from '@change-corgi/design-system/icons';
import { Flex } from '@change-corgi/design-system/layout';
import { buttonResetStyles } from '@change-corgi/design-system/reset';
import type { ColorName } from '@change-corgi/design-system/theme';
import { Text } from '@change-corgi/design-system/typography';

import { useShareLinks } from './hooks/useShareLinks';
import type { Channel } from './hooks/useShareLinks';

const ICON_MAP: Record<Channel, IconComponent> = {
	twitter: iconTwitter,
	facebook: iconFacebook,
	email: iconEmail,
	whatsapp: iconWhatsapp,
};
const ICON_COLOR_MAP: Record<Channel, ColorName> = {
	twitter: 'social-twitter',
	facebook: 'social-facebook',
	email: 'social-email',
	whatsapp: 'social-whatsapp',
};
const TEXT_COLOR_MAP: Record<Channel, ColorName> = {
	twitter: 'social-twitter',
	facebook: 'social-facebook',
	email: 'social-email',
	whatsapp: 'social-copy',
};
const I18N_MAP: Record<Channel, TranslationKey> = {
	twitter: translationKey('fe.pages.petition_update.twitter_button'),
	facebook: translationKey('fe.pages.petition_update.facebook_button'),
	email: translationKey('fe.components.petition_share.email'),
	whatsapp: translationKey('fe.pages.share_for_starters.whatsapp.basic_button'),
};

export function ShareLinks() {
	const {
		data: { shareActions },
	} = useShareLinks();

	return (
		<Flex sx={{ alignItems: 'center', flexWrap: 'wrap' }}>
			{shareActions.map(({ channel, action }) => (
				<button
					key={channel}
					data-testid={`update-share-${channel}`}
					data-qa={`update-share-${channel}`}
					type="button"
					sx={{ ...buttonResetStyles, mr: 16, whiteSpace: 'nowrap', mb: 8 }}
					onClick={action}
				>
					<Flex sx={{ alignItems: 'center' }}>
						<Icon icon={ICON_MAP[channel]} size="l" color={ICON_COLOR_MAP[channel]} mr={4} />
						<Text color={TEXT_COLOR_MAP[channel]}>
							<Translate value={I18N_MAP[channel]} />
						</Text>
					</Flex>
				</button>
			))}
		</Flex>
	);
}
